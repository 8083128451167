import React, { useEffect, useState } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { toast } from "react-toastify";
import {
  getAdminDashboard,
  getAdminDashboardCardDats,
  getAdminDashboardCertData,
  getCSVink,
} from "../../api/server";
import { ThreeDots } from "react-loader-spinner";
import PaginatedItems from "./pagination";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CompleteReport from "./CompleteReport";

const Dashboard = () => {
  const [enrolledFilter, setEnrolledFilter] = useState("Month");
  const [completedFilter, setCompletedFilter] = useState("Month");
  const [phoneFilter, setPhoneFilter] = useState("Month");
  const [certificateFilter, setCertificateFilter] = useState("Month");
  const [activeDate, setActiveDate] = useState();


  const validationSchema = Yup.object({
    startEnroll:
      enrolledFilter === "Date" && activeDate === "Enroll"
        ? Yup.date().required("Start date is required")
        : Yup.date(),
    endEnroll:
      enrolledFilter === "Date" && activeDate === "Enroll"
        ? Yup.date()
          .required("End date is required")
          .min(Yup.ref("startEnroll"), "End date must be greater than start")
        : Yup.date(),
    startCompleted:
      completedFilter === "Date" && activeDate === "Completed"
        ? Yup.date().required("Start date is required")
        : Yup.date(),
    endCompleted:
      completedFilter === "Date" && activeDate === "Completed"
        ? Yup.date()
          .required("End date is required")
          .min(
            Yup.ref("startCompleted"),
            "End date must be greater than start"
          )
        : Yup.date(),
    startPhone:
      phoneFilter === "Date" && activeDate === "Phone"
        ? Yup.date().required("Start date is required")
        : Yup.date(),
    endPhone:
      phoneFilter === "Date" && activeDate === "Phone"
        ? Yup.date()
          .required("End date is required")
          .min(Yup.ref("startPhone"), "End date must be greater than start")
        : Yup.date(),
    startCert:
      certificateFilter === "Date" && activeDate === "Cerificate"
        ? Yup.date().required("Start date is required")
        : Yup.date(),
    endCert:
      certificateFilter === "Date" && activeDate === "Cerificate"
        ? Yup.date()
          .required("End date is required")
          .min(Yup.ref("startPhone"), "End date must be greater than start")
        : Yup.date(),
  });

  const [currentCourseChart, setCurentCourseChart] = useState(
    "clientWhoCompleteFirst"
  );
  const [stats, setStats] = useState();
  const [getloading, setGetLoading] = useState(true);
  const [activeCard, setActiveCard] = useState(null);
  const [cardData, setCardData] = useState();
  const [cardLoading, setCardLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();
  const [statCourse, setStatCourse] = useState("All");

  const [cardDataEnrolled, setCardDataEnrolled] = useState();
  const [cardLoadingEnrolled, setCardLoadingEnrolled] = useState(false);
  const [pageNoEnrolled, setPageNoEnrolled] = useState(1);
  const [totalEnrolled, setTotalEnrolled] = useState();
  const [statCourseEnrolled, setStatCourseEnrolled] = useState("All");

  const [cardDataCompleted, setCardDataCompleted] = useState();
  const [cardLoadingCompleted, setCardLoadingCompleted] = useState(false);
  const [pageNoCompleted, setPageNoCompleted] = useState(1);
  const [totalCompleted, setTotalCompleted] = useState();
  const [statCourseCompleted, setStatCourseCompleted] = useState("All");

  const [cardDataPhone, setCardDataPhone] = useState();
  const [cardLoadingPhone, setCardLoadingPhone] = useState(false);
  const [pageNoPhone, setPageNoPhone] = useState(1);
  const [totalPhone, setTotalPhone] = useState();
  const [statCoursePhone, setStatCoursePhone] = useState("All");

  const [cardDataAllCertificate, setCardDataAllCertificate] = useState();
  const [cardDataCertificate, setCardDataCertificate] = useState();
  const [cardLoadingCertificate, setCardLoadingCertificate] = useState(false);
  const [pageNoCertificate, setPageNoCertificate] = useState(1);
  const [totalCertificate, setTotalCertificate] = useState();
  const [statCourseCertificate, setStatCourseCertificate] = useState("All");

  const [cSVLoadingSales, setCSVLoadingSales] = useState(false);
  const [cSVLoadingEnrolled, setCSVLoadingEnrolled] = useState(false);
  const [cSVLoadingCompleted, setCSVLoadingCompleted] = useState(false);
  const [cSVLoadingPhone, setCSVLoadingPhone] = useState(false);
  const [cSVLoadingCertificate, setCSVLoadingCertificate] = useState(false);

  const [filterLoading, setFilterLoading] = useState(false);

  const {
    handleChange,
    touched,
    values,
    errors,
    handleSubmit,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: {
      startEnroll: "",
      endEnroll: "",
      startCompleted: "",
      endCompleted: "",
      startPhone: "",
      endPhone: "",
      startCert: "",
      endCert: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setFilterLoading(true);
      if (activeDate === "Enroll") {
        fetchDataEnrolled();
      } else if (activeDate === "Completed") {
        fetchDataCompleted();
      } else if (activeDate === "Phone") {
        fetchDataPhone();
      } else {
        fetchDataCertificate();
      }
    },
  });

  const fetchDataEnrolled = async () => {
    if (
      enrolledFilter === "Date" &&
      values.startEnroll === "" &&
      values.endEnroll === ""
    ) {
    } else {
      setCardLoadingEnrolled(true);
      try {
        const res = await getAdminDashboardCardDats(
          enrolledFilter === "Date"
            ? "salesDate"
            : enrolledFilter === "Today"
              ? "sales" + enrolledFilter
              : "salesThis" + enrolledFilter,
          pageNoEnrolled,
          statCourseEnrolled,
          enrolledFilter === "Date"
            ? `&startDate=${values.startEnroll}&endDate=${values.endEnroll}`
            : ""
        );
        const data = res.data.data.result;
        const newTotal = res.data.data.total;

        setCardLoadingEnrolled(false);
        setFilterLoading(false);

        // Set state with new data
        setCardDataEnrolled(data);
        setTotalEnrolled(newTotal);
      } catch (err) {
        toast.error(err.response.data.error);
      } finally {
        setCardLoadingEnrolled(false);
        setFilterLoading(false);
      }
    }
  };
  const fetchDataCompleted = async () => {
    if (
      completedFilter === "Date" &&
      values.startCompleted === "" &&
      values.endCompleted === ""
    ) {
    } else {
      setCardLoadingCompleted(true);
      try {
        const res = await getAdminDashboardCardDats(
          completedFilter === "Date"
            ? "completedDate"
            : completedFilter === "Today"
              ? "completed" + completedFilter
              : "completedThis" + completedFilter,
          pageNoCompleted,
          statCourseCompleted,
          completedFilter === "Date"
            ? `&startDate=${values.startCompleted}&endDate=${values.endCompleted}`
            : ""
        );
        const data = res.data.data.result;
        const newTotal = res.data.data.total;

        setCardLoadingCompleted(false);
        setFilterLoading(false);

        // Set state with new data
        setCardDataCompleted(data);
        setTotalCompleted(newTotal);
      } catch (err) {
        toast.error(err.response.data.error);
      } finally {
        setCardLoadingCompleted(false);
        setFilterLoading(false);
      }
    }
  };
  const fetchDataPhone = async () => {
    if (
      phoneFilter === "Date" &&
      values.startPhone === "" &&
      values.endPhone === ""
    ) {
    } else {
      setCardLoadingPhone(true);
      try {
        const res = await getAdminDashboardCardDats(
          phoneFilter === "Date"
            ? "completedPhoneDate"
            : phoneFilter === "Today"
              ? "phoneCompleted" + phoneFilter
              : "phoneCompletedThis" + phoneFilter,
          pageNoPhone,
          statCoursePhone,
          phoneFilter === "Date"
            ? `&startDate=${values.startPhone}&endDate=${values.endPhone}`
            : ""
        );
        const data = res.data.data.result;
        const newTotal = res.data.data.total;

        setCardLoadingPhone(false);
        setFilterLoading(false);

        // Set state with new data
        setCardDataPhone(data);
        setTotalPhone(newTotal);
      } catch (err) {
        toast.error(err.response.data.error);
      } finally {
        setCardLoadingPhone(false);
        setFilterLoading(false);
      }
    }
  };
  const fetchDataCertificate = async () => {
    if (
      certificateFilter === "Date" &&
      values.startCert === "" &&
      values.endCert === ""
    ) {
    } else {
      setCardLoadingCertificate(true);
      try {
        const res = await getAdminDashboardCertData(
          certificateFilter === "Date"
            ? "certDate"
            : certificateFilter === "Today"
              ? "cert" + certificateFilter
              : "certThis" + certificateFilter,
          statCourseCertificate,
          certificateFilter === "Date"
            ? `&startDate=${values.startCert}&endDate=${values.endCert}`
            : ""
        );
        const data = res.data.data;
        const newTotal = res.data.data.length;

        setCardLoadingCertificate(false);
        setFilterLoading(false);

        // Set state with new data
        setCardDataAllCertificate(data);
        setCardDataCertificate(data.slice(0, 10));
        setTotalCertificate(newTotal);
      } catch (err) {
        toast.error(err.response.data.error);
      } finally {
        setCardLoadingCertificate(false);
        setFilterLoading(false);
      }
    }
  };

  const donwloadCSV = async (setLoading, values) => {
    setLoading(true);
    try {
      const res = await getCSVink(values);
      setLoading(false);
      const linkHandler = document.getElementById("csvhandler");
      if (linkHandler) {
        linkHandler.setAttribute("href", res.data.csvlink);
        linkHandler.click();
      }
    } catch (err) {
      toast.error(err.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAdminDashboard()
        .then((res) => {
          const data = res.data.data;
          setStats({
            dailyTotal: data.dailyTotal,
            monthTotal: data.monthTotal,
            weekTotal: data.weekTotal,
          });
          setGetLoading(false);
        })
        .catch((err) => {
          toast.error(err.response?.data.error);
          setGetLoading(false);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setCardLoading(true);
      try {
        const res = await getAdminDashboardCardDats(
          activeCard.replace(/ /g, ""),
          pageNo,
          statCourse
        );
        const data = res.data.data.result;
        const newTotal = res.data.data.total;

        setCardLoading(false);

        // Set state with new data
        setCardData(data);
        setTotal(newTotal);
      } catch (err) {
        toast.error(err.response.data.error);
      } finally {
        setCardLoading(false);
      }
    };
    if (activeCard) {
      fetchData();
    }
  }, [activeCard, pageNo, statCourse]);

  useEffect(() => {
    if (enrolledFilter !== "Date") {
      setValues({ ...values, startEnroll: "", endEnroll: "" });
    }
    fetchDataEnrolled();
  }, [enrolledFilter, pageNoEnrolled, statCourseEnrolled]);

  useEffect(() => {
    if (completedFilter !== "Date") {
      setValues({ ...values, startCompleted: "", endCompleted: "" });
    }
    fetchDataCompleted();
  }, [completedFilter, pageNoCompleted, statCourseCompleted]);

  useEffect(() => {
    if (phoneFilter !== "Date") {
      setValues({ ...values, startPhone: "", endPhone: "" });
    }
    fetchDataPhone();
  }, [phoneFilter, pageNoPhone, statCoursePhone]);

  useEffect(() => {
    if (certificateFilter !== "Date") {
      setValues({ ...values, startCert: "", endCert: "" });
    }
    fetchDataCertificate();
  }, [certificateFilter, statCourseCertificate]);

  useEffect(() => {
    if (cardDataAllCertificate && cardDataAllCertificate.length > 0) {
      if (certificateFilter !== "Date") {
        setValues({ ...values, startCert: "", endCert: "" });
      }
      if (
        certificateFilter === "Date" &&
        values.startCert === "" &&
        values.endCert === ""
      ) {
      } else {
        setCardLoadingCertificate(true);
        const data = cardDataAllCertificate.slice(
          (pageNoCertificate - 1) * 10,
          pageNoCertificate * 10
        );
        const newTotal = cardDataAllCertificate.length;
        setTimeout(() => {
          setCardLoadingCertificate(false);
          setFilterLoading(false);

          // Set state with new data
          setCardDataCertificate(data);
          setTotalCertificate(newTotal);
        }, 1000);
      }
    }
  }, [pageNoCertificate]);

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        {/* <!-- Page Content Holder --> */}
        <div id="content">
          <DashboardNavbar />
          <a id="csvhandler" target="_blank"></a>
          {getloading ? (
            <ThreeDots
              height="40"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
              }}
              wrapperClassName="spinner"
              visible={true}
            />
          ) : (
            <section className="dasboard">
              <div className="dasboard-status">
                <div className="row">
                  <div className="col-lg-4 col-container">
                    <div
                      className="card"
                      onClick={() => {
                        setActiveCard("sales Today");
                        setPageNo(1);
                        setStatCourse("All");
                      }}
                    >
                      <div className="card-body">
                        <h4>${stats.dailyTotal.toFixed(2)}</h4>
                        <p>Sales Today</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-container">
                    <div
                      className="card"
                      onClick={() => {
                        setActiveCard("sales This Week");
                        setPageNo(1);
                        setStatCourse("All");
                      }}
                    >
                      <div className="card-body">
                        <h4>${stats?.weekTotal?.toFixed(2)}</h4>
                        <p>Sales This Week</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-container">
                    <div
                      className="card"
                      onClick={() => {
                        setActiveCard("sales This Month");
                        setPageNo(1);
                        setStatCourse("All");
                      }}
                    >
                      <div className="card-body">
                        <h4>${stats.monthTotal.toFixed(2)}</h4>
                        <p>Sales This Month</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {activeCard && (
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <div className="card" style={{ height: "auto" }}>
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <h3
                            style={{
                              fontWeight: "bold",
                              fontSize: "22px",
                              textAlign: "left",
                              textTransform: "capitalize",
                            }}
                          >
                            {activeCard}
                          </h3>
                          <div className="d-flex justify-content-between align-items-center">
                            <button
                              className="btn_brnd "
                              style={{ padding: "6px 30px", fontWeight: "500" }}
                              type="button"
                              disabled={cSVLoadingSales}
                              onClick={() => {
                                donwloadCSV(setCSVLoadingSales, {
                                  course: statCourse,
                                  range:
                                    activeCard === "sales Today"
                                      ? activeCard.split(" ")[1]
                                      : activeCard.split(" ")[2],
                                  type: "Sale",
                                });
                              }}
                            >
                              {cSVLoadingSales ? (
                                <ThreeDots
                                  height="20"
                                  width="94"
                                  radius="9"
                                  color="#4fa94d"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{
                                    display: "block",
                                  }}
                                  wrapperClassName="spinner"
                                  visible={true}
                                />
                              ) : (
                                "Export Data"
                              )}
                            </button>
                            <select
                              className="form-select ms-4"
                              style={{ width: "150px" }}
                              aria-label="Default select example"
                              value={statCourse}
                              onChange={(e) => {
                                setStatCourse(e.target.value);
                                setPageNo(1);
                              }}
                            >
                              <option value="CC">CC</option>
                              <option value="DE">DE</option>
                              <option value="All" selected>
                                All
                              </option>
                            </select>
                          </div>
                        </div>
                        <table className="table table-borderless table-responsive">
                          <thead>
                            <tr>
                              <th scope="col" style={{ paddingBottom: "0px" }}>
                                Name
                              </th>
                              <th scope="col" style={{ paddingBottom: "0px" }}>
                                Email
                              </th>
                              <th scope="col" style={{ paddingBottom: "0px" }}>
                                Amount
                              </th>
                              <th scope="col" style={{ paddingBottom: "0px" }}>
                                Payment Date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cardLoading ? (
                              <tr>
                                <td colSpan={4}>
                                  <ThreeDots
                                    height="40"
                                    width="80"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                </td>
                              </tr>
                            ) : cardData && cardData.length > 0 ? (
                              cardData.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <p>
                                      <Link
                                        to={`/editstudent?id=${item.id}`}
                                        style={{
                                          color: "rgba(12, 25, 34, 0.4)",
                                        }}
                                      >
                                        {item.firstname} {item.lastname}
                                      </Link>
                                    </p>
                                  </td>
                                  <td>
                                    <div className="media">
                                      <div className="media-body">
                                        <a href={`mailto:${item.email}`}>
                                          {item.email}
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="media">
                                      <div className="media-body">
                                        {item.activated_first !== "" &&
                                          item.enrolFirstDate &&
                                          item.activated_second !== "" &&
                                          item.enrolSecondDate
                                          ? "$" +
                                          (Number(item.amount) +
                                            Number(item.amount))
                                          : "$" + Number(item.amount)}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="media">
                                      <div className="media-body">
                                        {item.enrolCourse === "CC"
                                          ? "CC: " +
                                          item.enrolDateCC?.split("T")[0]
                                          : item.enrolCourse === "DE"
                                            ? "DE: " +
                                            item.enrolDateDE?.split("T")[0]
                                            : item.enrolCourse === "CC & DE"
                                              ? "CC: " +
                                              item.enrolDateCC?.split("T")[0] +
                                              ", DE: " +
                                              item.enrolDateDE?.split("T")[0]
                                              : ""}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <td
                                colSpan={3}
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                No Data Found
                              </td>
                            )}
                          </tbody>
                        </table>
                        {!cardLoading && total !== 0 && total > 10 && (
                          <PaginatedItems
                            total={total}
                            pageNo={pageNo}
                            setPageNo={setPageNo}
                            pageSize={10}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-lg-12 mt-4">
                  {enrolledFilter === "Date" && (
                    <form
                      className="row align-items-center mt-5"
                      onSubmit={handleSubmit}
                    >
                      <h6 className="fw-bold text-center col-2 mb-4">
                        <span className="text_success">Date Range:</span>
                      </h6>
                      <div className="col-3">
                        <div
                          className="input_wrap"
                          style={{ marginBottom: "0px" }}
                        >
                          <label className="label-title">Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            style={{ height: "50px" }}
                            placeholder="Enter start date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="startEnroll"
                            value={values.startEnroll}
                          />

                          <p
                            className="text-danger mb-0"
                            style={{ minHeight: "25px" }}
                          >
                            {errors.startEnroll && touched.startEnroll
                              ? errors.startEnroll
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-center">
                        <div className="form-group">
                          <label className="label-title  ">To</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div
                          className="input_wrap"
                          style={{ marginBottom: "0px" }}
                        >
                          <label className="label-title"> End Date</label>
                          <input
                            type="date"
                            className="form-control"
                            style={{ height: "50px" }}
                            placeholder="Enter end date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="endEnroll"
                            value={values.endEnroll}
                          />
                          <p
                            className="text-danger mb-0"
                            style={{ minHeight: "25px" }}
                          >
                            {errors.endEnroll && touched.endEnroll
                              ? errors.endEnroll
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="text-center col-2  mb-4">
                        <button
                          className="btn_brnd"
                          disabled={activeDate === "Enroll" && filterLoading}
                          type="submit"
                          onClick={() => {
                            setActiveDate("Enroll");
                          }}
                        >
                          {activeDate === "Enroll" && filterLoading ? (
                            <ThreeDots
                              height="20"
                              width="60"
                              radius="9"
                              color="#4fa94d"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{
                                display: "block",
                              }}
                              wrapperClassName="spinner"
                              visible={true}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  )}

                  <div className="card" style={{ height: "auto" }}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            textAlign: "left",
                            textTransform: "capitalize",
                          }}
                        >
                          Students Enrolled
                        </h3>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="btn_brnd "
                            style={{ padding: "6px 30px", fontWeight: "500" }}
                            type="button"
                            disabled={cSVLoadingEnrolled}
                            onClick={() => {
                              donwloadCSV(setCSVLoadingEnrolled, {
                                course: statCourseEnrolled,
                                range: enrolledFilter,
                                type: "Enrolled",
                                startDate:
                                  enrolledFilter === "Date"
                                    ? values.startEnroll
                                    : null,
                                endDate:
                                  enrolledFilter === "Date"
                                    ? values.endEnroll
                                    : null,
                              });
                            }}
                          >
                            {cSVLoadingEnrolled ? (
                              <ThreeDots
                                height="20"
                                width="94"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            ) : (
                              "Export Data"
                            )}
                          </button>
                          <select
                            className="form-select ms-4"
                            style={{ width: "150px" }}
                            aria-label="Default select example"
                            value={enrolledFilter}
                            onChange={(e) => {
                              setEnrolledFilter(e.target.value);
                              setPageNoEnrolled(1);
                            }}
                          >
                            <option value="Today">Today</option>
                            <option value="Week">Week</option>
                            <option selected value="Month">
                              Month
                            </option>
                            <option value="Date">Custom Range</option>
                          </select>
                          <select
                            className="form-select ms-4"
                            style={{ width: "150px" }}
                            aria-label="Default select example"
                            value={statCourseEnrolled}
                            onChange={(e) => {
                              setStatCourseEnrolled(e.target.value);
                              setPageNoEnrolled(1);
                            }}
                          >
                            <option value="CC">CC</option>
                            <option value="DE">DE</option>
                            <option value="All" selected>
                              All
                            </option>
                          </select>
                        </div>
                      </div>
                      <table className="table table-borderless table-responsive">
                        <thead>
                          <tr>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Name
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Email
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Enrolled Course
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Enrollment Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cardLoadingEnrolled ? (
                            <tr>
                              <td colSpan={4}>
                                <ThreeDots
                                  height="40"
                                  width="80"
                                  radius="9"
                                  color="#4fa94d"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  wrapperClassName="spinner"
                                  visible={true}
                                />
                              </td>
                            </tr>
                          ) : cardDataEnrolled &&
                            cardDataEnrolled.length > 0 ? (
                            cardDataEnrolled.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p>
                                    <Link
                                      to={`/editstudent?id=${item.id}`}
                                      style={{
                                        color: "rgba(12, 25, 34, 0.4)",
                                      }}
                                    >
                                      <div className="flex-i gAddBox__row">
                                        {item.firstname} {item.lastname}
                                        {item?.googleADs == true ? <span className="gAddBox flex-a">AD</span> : ""}
                                      </div>
                                    </Link>
                                  </p>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      <a href={`mailto:${item.email}`}>
                                        {item.email}
                                      </a>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      {item.enrolCourse}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      {item.enrolCourse === "CC"
                                        ? "CC: " +
                                        item.enrolDateCC?.split("T")[0]
                                        : item.enrolCourse === "DE"
                                          ? "DE: " +
                                          item.enrolDateDE?.split("T")[0]
                                          : item.enrolCourse === "CC & DE"
                                            ? "CC: " +
                                            item.enrolDateCC?.split("T")[0] +
                                            ", DE: " +
                                            item.enrolDateDE?.split("T")[0]
                                            : ""}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <td
                              colSpan={3}
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              No Data Found
                            </td>
                          )}
                        </tbody>
                      </table>
                      {!cardLoadingEnrolled &&
                        totalEnrolled !== 0 &&
                        totalEnrolled > 10 && (
                          <PaginatedItems
                            total={totalEnrolled}
                            pageNo={pageNoEnrolled}
                            setPageNo={setPageNoEnrolled}
                            pageSize={10}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-4">
                  {completedFilter === "Date" && (
                    <form
                      className="row align-items-center mt-5"
                      onSubmit={handleSubmit}
                    >
                      <h6 className="fw-bold text-center col-2 mb-4">
                        <span className="text_success">Date Range:</span>
                      </h6>
                      <div className="col-3">
                        <div
                          className="input_wrap"
                          style={{ marginBottom: "0px" }}
                        >
                          <label className="label-title">Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            style={{ height: "50px" }}
                            placeholder="Enter start date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="startCompleted"
                            value={values.startCompleted}
                          />

                          <p
                            className="text-danger mb-0"
                            style={{ minHeight: "25px" }}
                          >
                            {errors.startCompleted && touched.startCompleted
                              ? errors.startCompleted
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-center">
                        <div className="form-group">
                          <label className="label-title  ">To</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div
                          className="input_wrap"
                          style={{ marginBottom: "0px" }}
                        >
                          <label className="label-title"> End Date</label>
                          <input
                            type="date"
                            className="form-control"
                            style={{ height: "50px" }}
                            placeholder="Enter end date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="endCompleted"
                            value={values.endCompleted}
                          />
                          <p
                            className="text-danger mb-0"
                            style={{ minHeight: "25px" }}
                          >
                            {errors.endCompleted && touched.endCompleted
                              ? errors.endCompleted
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="text-center col-2  mb-4">
                        <button
                          className="btn_brnd"
                          disabled={activeDate === "Completed" && filterLoading}
                          type="submit"
                          onClick={() => {
                            setActiveDate("Completed");
                          }}
                        >
                          {activeDate === "Completed" && filterLoading ? (
                            <ThreeDots
                              height="20"
                              width="60"
                              radius="9"
                              color="#4fa94d"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{
                                display: "block",
                              }}
                              wrapperClassName="spinner"
                              visible={true}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                  <div className="card" style={{ height: "auto" }}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            textAlign: "left",
                            textTransform: "capitalize",
                          }}
                        >
                          Course Completed
                        </h3>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="btn_brnd "
                            style={{ padding: "6px 30px", fontWeight: "500" }}
                            type="button"
                            disabled={cSVLoadingCompleted}
                            onClick={() => {
                              donwloadCSV(setCSVLoadingCompleted, {
                                course: statCourseCompleted,
                                range: completedFilter,
                                type: "Completed",
                                startDate:
                                  completedFilter === "Date"
                                    ? values.startCompleted
                                    : null,
                                endDate:
                                  completedFilter === "Date"
                                    ? values.endCompleted
                                    : null,
                              });
                            }}
                          >
                            {cSVLoadingCompleted ? (
                              <ThreeDots
                                height="20"
                                width="94"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            ) : (
                              "Export Data"
                            )}
                          </button>
                          <select
                            className="form-select ms-4"
                            style={{ width: "150px" }}
                            aria-label="Default select example"
                            value={completedFilter}
                            onChange={(e) => {
                              setCompletedFilter(e.target.value);
                              setPageNoCompleted(1);
                            }}
                          >
                            <option value="Today">Today</option>
                            <option value="Week">Week</option>
                            <option selected value="Month">
                              Month
                            </option>
                            <option value="Date">Custom Range</option>
                          </select>
                          <select
                            className="form-select ms-4"
                            style={{ width: "150px" }}
                            aria-label="Default select example"
                            value={statCourseCompleted}
                            onChange={(e) => {
                              setStatCourseCompleted(e.target.value);
                              setPageNoCompleted(1);
                            }}
                          >
                            <option value="CC">CC</option>
                            <option value="DE">DE</option>
                            <option value="All" selected>
                              All
                            </option>
                          </select>
                        </div>
                      </div>
                      <table className="table table-borderless table-responsive">
                        <thead>
                          <tr>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Name
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Email
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Completed Course
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Completion Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cardLoadingCompleted ? (
                            <tr>
                              <td colSpan={4}>
                                <ThreeDots
                                  height="40"
                                  width="80"
                                  radius="9"
                                  color="#4fa94d"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  wrapperClassName="spinner"
                                  visible={true}
                                />
                              </td>
                            </tr>
                          ) : cardDataCompleted &&
                            cardDataCompleted.length > 0 ? (
                            cardDataCompleted.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p>
                                    <Link
                                      to={`/editstudent?id=${item.id}`}
                                      style={{
                                        color: "rgba(12, 25, 34, 0.4)",
                                      }}
                                    >
                                      <div className="flex-i gAddBox__row">
                                        {item.firstname} {item.lastname}
                                        {item?.googleADs == true ? <span className="gAddBox flex-a">AD</span> : ""}
                                      </div>
                                    </Link>
                                  </p>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      <a href={`mailto:${item.email}`}>
                                        {item.email}
                                      </a>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      {item.completedCourse}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      {item.completedCourse === "CC"
                                        ? "CC: " +
                                        item.completedDateCC?.split("T")[0]
                                        : item.completedCourse === "DE"
                                          ? "DE: " +
                                          item.completedDateDE?.split("T")[0]
                                          : item.completedCourse === "CC & DE"
                                            ? "CC: " +
                                            item.completedDateCC?.split("T")[0] +
                                            ", DE: " +
                                            item.completedDateDE?.split("T")[0]
                                            : ""}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <td
                              colSpan={3}
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              No Data Found
                            </td>
                          )}
                        </tbody>
                      </table>
                      {!cardLoadingCompleted &&
                        totalCompleted !== 0 &&
                        totalCompleted > 10 && (
                          <PaginatedItems
                            total={totalCompleted}
                            pageNo={pageNoCompleted}
                            setPageNo={setPageNoCompleted}
                            pageSize={10}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-4">
                  {phoneFilter === "Date" && (
                    <form
                      className="row align-items-center mt-5"
                      onSubmit={handleSubmit}
                    >
                      <h6 className="fw-bold text-center col-2 mb-4">
                        <span className="text_success">Date Range:</span>
                      </h6>
                      <div className="col-3">
                        <div
                          className="input_wrap"
                          style={{ marginBottom: "0px" }}
                        >
                          <label className="label-title">Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            style={{ height: "50px" }}
                            placeholder="Enter start date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="startPhone"
                            value={values.startPhone}
                          />

                          <p
                            className="text-danger mb-0"
                            style={{ minHeight: "25px" }}
                          >
                            {errors.startPhone && touched.startPhone
                              ? errors.startPhone
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-center">
                        <div className="form-group">
                          <label className="label-title  ">To</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div
                          className="input_wrap"
                          style={{ marginBottom: "0px" }}
                        >
                          <label className="label-title"> End Date</label>
                          <input
                            type="date"
                            className="form-control"
                            style={{ height: "50px" }}
                            placeholder="Enter end date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="endPhone"
                            value={values.endPhone}
                          />
                          <p
                            className="text-danger mb-0"
                            style={{ minHeight: "25px" }}
                          >
                            {errors.endPhone && touched.endPhone
                              ? errors.endPhone
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="text-center col-2  mb-4">
                        <button
                          className="btn_brnd"
                          disabled={activeDate === "Phone" && filterLoading}
                          type="submit"
                          onClick={() => {
                            setActiveDate("Phone");
                          }}
                        >
                          {activeDate === "Phone" && filterLoading ? (
                            <ThreeDots
                              height="20"
                              width="60"
                              radius="9"
                              color="#4fa94d"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{
                                display: "block",
                              }}
                              wrapperClassName="spinner"
                              visible={true}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                  <div className="card" style={{ height: "auto" }}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            textAlign: "left",
                            textTransform: "capitalize",
                          }}
                        >
                          Phone Courses
                        </h3>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="btn_brnd "
                            style={{ padding: "6px 30px", fontWeight: "500" }}
                            type="button"
                            disabled={cSVLoadingPhone}
                            onClick={() => {
                              donwloadCSV(setCSVLoadingPhone, {
                                course: statCoursePhone,
                                range: phoneFilter,
                                type: "Phone",
                                startDate:
                                  phoneFilter === "Date"
                                    ? values.startPhone
                                    : null,
                                endDate:
                                  phoneFilter === "Date"
                                    ? values.endPhone
                                    : null,
                              });
                            }}
                          >
                            {cSVLoadingPhone ? (
                              <ThreeDots
                                height="20"
                                width="94"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            ) : (
                              "Export Data"
                            )}
                          </button>
                          <select
                            className="form-select ms-4"
                            style={{ width: "150px" }}
                            aria-label="Default select example"
                            value={phoneFilter}
                            onChange={(e) => {
                              setPhoneFilter(e.target.value);
                              setPageNoPhone(1);
                            }}
                          >
                            <option value="Today">Today</option>
                            <option value="Week">Week</option>
                            <option selected value="Month">
                              Month
                            </option>
                            <option value="Date">Custom Range</option>
                          </select>
                          <select
                            className="form-select ms-4"
                            style={{ width: "150px" }}
                            aria-label="Default select example"
                            value={statCoursePhone}
                            onChange={(e) => {
                              setStatCoursePhone(e.target.value);
                              setPageNoPhone(1);
                            }}
                          >
                            <option value="CC">CC</option>
                            <option value="DE">DE</option>
                            <option value="All" selected>
                              All
                            </option>
                          </select>
                        </div>
                      </div>
                      <table className="table table-borderless table-responsive">
                        <thead>
                          <tr>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Name
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Email
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Completed Course
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Completion Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cardLoadingPhone ? (
                            <tr>
                              <td colSpan={4}>
                                <ThreeDots
                                  height="40"
                                  width="80"
                                  radius="9"
                                  color="#4fa94d"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  wrapperClassName="spinner"
                                  visible={true}
                                />
                              </td>
                            </tr>
                          ) : cardDataPhone && cardDataPhone.length > 0 ? (
                            cardDataPhone.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p>
                                    <Link
                                      to={`/editstudent?id=${item.id}`}
                                      style={{
                                        color: "rgba(12, 25, 34, 0.4)",
                                      }}
                                    >
                                      <div className="flex-i gAddBox__row">
                                        {item.firstname} {item.lastname}
                                        {item?.googleADs == true ? <span className="gAddBox flex-a">AD</span> : ""}
                                      </div>
                                    </Link>
                                  </p>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      <a href={`mailto:${item.email}`}>
                                        {item.email}
                                      </a>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      {item.completedCourse}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      {item.completedCourse === "CC"
                                        ? "CC: " +
                                        item.completedDateCC?.split("T")[0]
                                        : item.completedCourse === "DE"
                                          ? "DE: " +
                                          item.completedDateDE?.split("T")[0]
                                          : item.completedCourse === "CC & DE"
                                            ? "CC: " +
                                            item.completedDateCC?.split("T")[0] +
                                            ", DE: " +
                                            item.completedDateDE?.split("T")[0]
                                            : ""}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <td
                              colSpan={3}
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              No Data Found
                            </td>
                          )}
                        </tbody>
                      </table>
                      {!cardLoadingPhone &&
                        totalPhone !== 0 &&
                        totalPhone > 10 && (
                          <PaginatedItems
                            total={totalPhone}
                            pageNo={pageNoPhone}
                            setPageNo={setPageNoPhone}
                            pageSize={10}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-4">
                  {certificateFilter === "Date" && (
                    <form
                      className="row align-items-center mt-5"
                      onSubmit={handleSubmit}
                    >
                      <h6 className="fw-bold text-center col-2 mb-4">
                        <span className="text_success">Date Range:</span>
                      </h6>
                      <div className="col-3">
                        <div
                          className="input_wrap"
                          style={{ marginBottom: "0px" }}
                        >
                          <label className="label-title">Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            style={{ height: "50px" }}
                            placeholder="Enter start date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="startCert"
                            value={values.startCert}
                          />

                          <p
                            className="text-danger mb-0"
                            style={{ minHeight: "25px" }}
                          >
                            {errors.startCert && touched.startCert
                              ? errors.startCert
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-center">
                        <div className="form-group">
                          <label className="label-title  ">To</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div
                          className="input_wrap"
                          style={{ marginBottom: "0px" }}
                        >
                          <label className="label-title"> End Date</label>
                          <input
                            type="date"
                            className="form-control"
                            style={{ height: "50px" }}
                            placeholder="Enter end date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="endCert"
                            value={values.endCert}
                          />
                          <p
                            className="text-danger mb-0"
                            style={{ minHeight: "25px" }}
                          >
                            {errors.endCert && touched.endCert
                              ? errors.endCert
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="text-center col-2  mb-4">
                        <button
                          className="btn_brnd"
                          disabled={
                            activeDate === "Certificate" && filterLoading
                          }
                          type="submit"
                          onClick={() => {
                            setActiveDate("Certificate");
                          }}
                        >
                          {activeDate === "Certificate" && filterLoading ? (
                            <ThreeDots
                              height="20"
                              width="60"
                              radius="9"
                              color="#4fa94d"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{
                                display: "block",
                              }}
                              wrapperClassName="spinner"
                              visible={true}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                  <div className="card" style={{ height: "auto" }}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            textAlign: "left",
                            textTransform: "capitalize",
                          }}
                        >
                          Certificate Issued
                        </h3>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="btn_brnd "
                            style={{ padding: "6px 30px", fontWeight: "500" }}
                            type="button"
                            disabled={cSVLoadingCertificate}
                            onClick={() => {
                              donwloadCSV(setCSVLoadingCertificate, {
                                course: statCourseCertificate,
                                range: certificateFilter,
                                type: "Certificate",
                                startDate:
                                  certificateFilter === "Date"
                                    ? values.startCert
                                    : null,
                                endDate:
                                  certificateFilter === "Date"
                                    ? values.endCert
                                    : null,
                              });
                            }}
                          >
                            {cSVLoadingCertificate ? (
                              <ThreeDots
                                height="20"
                                width="94"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            ) : (
                              "Export Data"
                            )}
                          </button>
                          <select
                            className="form-select ms-4"
                            style={{ width: "150px" }}
                            aria-label="Default select example"
                            value={certificateFilter}
                            onChange={(e) => {
                              setCertificateFilter(e.target.value);
                              setCardDataAllCertificate();
                              setPageNoCertificate(1);
                            }}
                          >
                            <option value="Today">Today</option>
                            <option value="Week">Week</option>
                            <option selected value="Month">
                              Month
                            </option>
                            <option value="Date">Custom Range</option>
                          </select>
                          <select
                            className="form-select ms-4"
                            style={{ width: "150px" }}
                            aria-label="Default select example"
                            value={statCourseCertificate}
                            onChange={(e) => {
                              setStatCourseCertificate(e.target.value);
                              setCardDataAllCertificate();
                              setPageNoCertificate(1);
                            }}
                          >
                            <option value="CC">CC</option>
                            <option value="DE">DE</option>
                            <option value="All" selected>
                              All
                            </option>
                          </select>
                        </div>
                      </div>
                      <table className="table table-borderless table-responsive">
                        <thead>
                          <tr>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Name
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Email
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Certificate Issued
                            </th>
                            <th scope="col" style={{ paddingBottom: "0px" }}>
                              Issue Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cardLoadingCertificate ? (
                            <tr>
                              <td colSpan={4}>
                                <ThreeDots
                                  height="40"
                                  width="80"
                                  radius="9"
                                  color="#4fa94d"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  wrapperClassName="spinner"
                                  visible={true}
                                />
                              </td>
                            </tr>
                          ) : cardDataCertificate &&
                            cardDataCertificate.length > 0 ? (
                            cardDataCertificate.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p>
                                    <Link
                                      to={`/editstudent?id=${item.id}`}
                                      style={{
                                        color: "rgba(12, 25, 34, 0.4)",
                                      }}
                                    >
                                      <div className="flex-i gAddBox__row">
                                        {item.firstname} {item.lastname} {item.courseSecondType == "Joint" ? "(Joint)" : item.courseFirstType == "Joint" ? "(Joint)" : ""}
                                        {item?.googleADs == true ? <span className="gAddBox flex-a">AD</span> : ""}
                                      </div>
                                    </Link>

                                  </p>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      <a href={`mailto:${item.email}`}>
                                        {item.email}
                                      </a>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      {item.certificateCourse}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      {item.certificateCourse === "CC"
                                        ? "CC: " +
                                        item.certificateDateCC?.split("T")[0]
                                        : item.certificateCourse === "DE"
                                          ? "DE: " +
                                          item.certificateDateDE?.split("T")[0]
                                          : item.certificateCourse === "CC & DE"
                                            ? "CC: " +
                                            item.certificateDateCC?.split(
                                              "T"
                                            )[0] +
                                            ", DE: " +
                                            item.certificateDateDE?.split("T")[0]
                                            : ""}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <td
                              colSpan={3}
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              No Data Found
                            </td>
                          )}
                        </tbody>
                      </table>
                      {!cardLoadingCertificate &&
                        totalCertificate !== 0 &&
                        totalCertificate > 10 && (
                          <PaginatedItems
                            total={totalCertificate}
                            pageNo={pageNoCertificate}
                            setPageNo={setPageNoCertificate}
                            pageSize={10}
                          />
                        )}
                    </div>
                  </div>
                  {/* <div className="mb-4">
                  <CompleteReport />
                  </div> */}

                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
