
import Footer from "../../layouts/footer"
import Header from "../../layouts/header"
import logo from "../../assets/images/Credit-Rebuilder-Logo2 - Copy.png"
const Resources = () => {
  return (
    <>
      <Header />
      {/* <div className="space_for_website_top"></div> */}
      <section className="financialRec">
        <div className="container ">
          <div className="col-lg-6">
            <img src={logo} alt="" className="financialRec__logo" />
            <h1 className="financial__heading">Resources for Your Financial Comeback</h1>
            <div className="financial__content">
              <p className="financial__text financial__text--mb1">
                Too often, people struggling with financial hardship are told what they can&apos;t do. They hear they
                can&apos;t get a car loan, can&apos;t buy a home, can&apos;t rebuild after a setback.
              </p>

              <p className="financial__text2">
                But we know the truth: <span className="financial__highlight ">you can.</span>
              </p>

              <p className="financial__text">
                Whether you&apos;re facing debt, considering bankruptcy, or working to rebuild your credit, we believe in you.
                Our goal is simple: to guide you from financial distress to financial stability—and then beyond.
                Because stability isn&apos;t the finish line. Our hope is that you build wealth.
              </p>

              <p className="financial__text">
                That journey starts with education, resources, and support from professionals who understand what you&apos;re going through.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Resources